import React, { useEffect, useRef } from 'react'
import { Label, LabelProps } from './Label'

import * as styles from './Input.styles'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  value?: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  type?: string
  placeholder?: string
  maxLength?: number
  focus?: boolean
  dataInputTestId?: string
  label?: LabelProps['text']
  iconClassName?: LabelProps['iconClassName']
  error?: LabelProps['error']
  dataErrorTestId?: LabelProps['dataErrorTestId']
  labelEndElement?: LabelProps['endElement']
  hideErrorText?: boolean
  fullWidth?: boolean
  inputCss?: Style
}

const Input: React.FC<InputProps> = ({
  name,
  value,
  onChange,
  type = 'text',
  placeholder,
  focus,
  maxLength = 255,
  dataInputTestId,
  error,
  label,
  iconClassName,
  dataErrorTestId,
  labelEndElement,
  disabled,
  hideErrorText,
  tabIndex,
  defaultValue,
  fullWidth = true,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (focus) {
      ref.current?.focus()
    }
  }, [focus])

  return (
    <div
      css={[
        styles.rootContainer,
        disabled && styles.disabled,
        fullWidth && styles.fullWidth,
      ]}
    >
      {(label || labelEndElement || (error && !hideErrorText)) && (
        <Label
          css={disabled && styles.disabled}
          text={label ?? ''}
          iconClassName={iconClassName}
          error={hideErrorText ? null : error}
          dataErrorTestId={dataErrorTestId}
          endElement={labelEndElement}
        />
      )}
      <input
        {...props}
        ref={ref}
        css={[styles.inputField(Boolean(error))]}
        value={value}
        onChange={onChange}
        type={type}
        name={name}
        placeholder={placeholder}
        data-testid={dataInputTestId}
        maxLength={maxLength}
        disabled={disabled}
        tabIndex={tabIndex}
        defaultValue={defaultValue}
      />
    </div>
  )
}

export default Input
